<template>
  <div class="drop">
    <overlay-loader :loading="isLoading"/>
    <vue-file-agent
      ref="dropZone"
      :multiple="false"
      :helpText="title"
      :maxFiles="1"
      :meta="true"
      :accept="fileType"
      :compact="true"
      :theme="theme"
      :maxSize="maxSize"
      :disabled="isLoading"
      @select="onFileSelected">
    </vue-file-agent>
    <!-- Test transition v-if -->
    <div class="drop__container" v-if="showSubmit && !isLoading">
      <div class="drop__wrapper">
        <hcc-button
          type="submit" size="sm"
          color="secondary" variant="outline" @click="submitFile">
          {{ $t('campaigns.data-bank.upload') }}
        </hcc-button>
        <hcc-button
          type="submit" size="sm" class="drop__button"
          color="alert" variant="outline" @click="clear">
          {{ $t('common.delete')}}
        </hcc-button>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import { REQUEST_UPLOAD, CREATE_DATA_BANK } from '@/eventTypes';

export default {
  props: {
    type: {
      required: true,
      type: String,
    },
    theme: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    order: {
      type: Number,
      required: true,
    },
    fileType: {
      required: true,
      type: String,
    },
  },
  components: {
    OverlayLoader,
    HccButton: () => import('@/components/shared/HccButton/index.vue'),
  },
  data() {
    return {
      file: null,
      isLoading: false,
    };
  },
  computed: {
    showSubmit() {
      return !!this.file;
    },
    hasError() {
      return this.fileErrors.some(val => val);
    },
    fileErrors() {
      return Object.values(this.$refs.dropZone.fileRecords[0].error);
    },
    maxSize() {
      return process.env.VUE_APP_UPLOAD_MAX_FILESIZE;
    },
  },
  mounted() {
    EventBus.$on(REQUEST_UPLOAD, () => {
      this.isLoading = false;
      this.clear();
    });
  },
  methods: {
    submitFile() {
      this.isLoading = true;
      const data = {
        order: this.order,
        type: this.type,
        multimedia: this.file,
      };

      EventBus.$emit(CREATE_DATA_BANK, data);
    },
    onFileSelected(files) {
      if (!this.hasError) {
        this.file = files[0].file;
      } else {
        this.deleteFile(files[0].file);
        this.showErrorAlert();
      }
    },
    clear() {
      if (this.file != null) {
        this.$refs.dropZone.deleteFileRecord(this.file);
        this.file = null;
      }
    },
    deleteFile(file) {
      this.$refs.dropZone.deleteFileRecord(file);
      this.file = null;
    },
    showErrorAlert() {
      this.$toasted.global.error(this.$t('campaigns.data-bank.drop-error'));
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/settings/_drop_zone.scss';
</style>
